/* Base Styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  background: #f5f7fa;
  color: #333;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* App Layout */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main.container {
  flex: 1;
  padding: 2rem 20px;
  display: flex;
  flex-direction: column;
}

/* Header & Footer */
.app-header {
  background-color: #2c3e50;
  color: white;
  padding: 1.5rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.app-header h1 {
  margin: 0;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.app-footer {
  background-color: #34495e;
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem 0;
  text-align: center;
  font-size: 0.9rem;
}

/* Home Screen */
.home-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 2rem 0;
}

.welcome-message {
  text-align: center;
  margin-bottom: 3rem;
}

.welcome-message h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.welcome-message p {
  font-size: 1.2rem;
  color: #7f8c8d;
}

.big-buttons {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
}

.big-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: none;
  border-radius: 16px;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: white;
}

.big-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.big-button.sign-in {
  background-color: #2ecc71;
}

.big-button.sign-in:hover {
  background-color: #27ae60;
}

.big-button.sign-out {
  background-color: #e74c3c;
}

.big-button.sign-out:hover {
  background-color: #c0392b;
}

.big-button .icon {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.big-button .button-text {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.big-button .description {
  font-size: 1rem;
  font-weight: normal;
  opacity: 0.8;
}

.help-text {
  margin-top: 3rem;
  text-align: center;
  color: #7f8c8d;
  font-size: 1.1rem;
}

/* Sign In & Sign Out Screens */
.screen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.screen-header h2 {
  font-size: 1.8rem;
  margin: 0;
  color: #2c3e50;
}

.back-button {
  background-color: #ecf0f1;
  color: #2c3e50;
  border: none;
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: #dde4e6;
}

.back-button.centered {
  margin: 0 auto;
  display: block;
}

/* Forms */
.big-form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #2c3e50;
}

.form-group input {
  width: 100%;
  padding: 1rem;
  font-size: 1.3rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.2s, background-color 0.2s;
}

.form-group input:focus {
  border-color: #3498db;
  background-color: white;
  outline: none;
}

.form-actions {
  margin-top: 3rem;
  text-align: center;
}

.submit-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 200px;
}

.submit-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Message Notification */
.message {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Visitor List */
.visitor-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.loading, .error, .no-visitors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  font-size: 1.2rem;
  color: #7f8c8d;
  flex: 1;
}

.error {
  color: #e74c3c;
}

.no-visitors p {
  margin-bottom: 2rem;
}

.visitor-list {
  flex: 1;
}

.visitor-list .instruction {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #7f8c8d;
}

.visitor-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.visitor-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
  background-color: white;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
}

.visitor-button:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.visitor-button:active {
  transform: translateY(0);
}

.visitor-info {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.visitor-name {
  font-size: 1.4rem;
  font-weight: 500;
  color: #2c3e50;
}

.visitor-course {
  font-size: 1rem;
  color: #7f8c8d;
}

.sign-out-icon {
  font-size: 1.5rem;
  color: #e74c3c;
  margin-left: 1rem;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .big-buttons {
    max-width: 500px;
  }
  
  .big-button {
    padding: 2.5rem;
  }
  
  .big-form {
    max-width: 700px;
  }
}

@media (max-width: 480px) {
  .app-header h1 {
    font-size: 1.6rem;
  }
  
  .welcome-message h2 {
    font-size: 2rem;
  }
  
  .big-button {
    padding: 1.5rem;
  }
  
  .big-button .button-text {
    font-size: 1.5rem;
  }
  
  .screen-header h2 {
    font-size: 1.5rem;
  }
  
  .back-button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
  }
  
  .form-group label {
    font-size: 1.1rem;
  }
  
  .form-group input {
    font-size: 1.1rem;
    padding: 0.8rem;
  }
  
  .submit-button {
    font-size: 1.1rem;
    padding: 0.8rem 1.5rem;
  }
  
  .visitor-name {
    font-size: 1.2rem;
  }
}